import '../../App.css';
import ServiceSection from '../ServiceSection'
import React from 'react'

function Services() {
  return (
    <>
        <ServiceSection />
    </>
  )
}

export default Services