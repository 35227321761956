import '../../App.css';
import FAQsSection from '../FAQsSection'
import React from 'react'

function FAQs() {
  return (
    <>
        <FAQsSection />
    </>
  )
}

export default FAQs