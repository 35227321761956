import '../../App.css';
import AboutUsSection from '../AboutUsSection';
import React from 'react';

function AboutUs() {
  return (
    <>
        <AboutUsSection />
    </>
  )
}

export default AboutUs
