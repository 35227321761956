import React from 'react'
import CareerInfo from '../CareerInfo.js'
import ApplyForm from '../ApplyForm.js'

function Careers() {
  return (
    <>
        <CareerInfo />
        <ApplyForm />
    </>
  )
}

export default Careers