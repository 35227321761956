import "../../App.css"
import React from 'react'
import ContactForm from "../ContactForm"

function ContactUs() {
  return (
    <>
      <ContactForm />
    </>
  )
}

export default ContactUs